<template>
  <ContentWrapper
    :id="contentId"
    :key="contentId"
    :class="`${backgroundMap[contentData?.background]}`">
    <div v-for="subcontent in contentData?.subcontents" :key="subcontent.item.id">
      <!-- prettier-ignore -->
      <CmsCustomerEvaluation
        v-if="subcontent.collection === 'review_wrapper'"
        :customer-evaluation="(subcontent.item as ReviewWrapper)" />

      <!-- prettier-ignore -->
      <CmsContact
        v-if="subcontent.collection === 'contact'"
        :contact="(subcontent.item as Contact)" />

      <!-- prettier-ignore -->
      <CmsText
        v-if="subcontent.collection === 'text'"
        :text="(subcontent.item as Text)" />

      <!-- prettier-ignore -->
      <CmsCustomTable
          v-if="subcontent.collection === 'custom_table'"
          :custom-table="(subcontent.item as CustomTable)" />

      <!-- prettier-ignore -->
      <CmsInfobox
        v-if="subcontent.collection === 'infobox'"
        :infobox="(subcontent.item as Infobox)" />

      <!-- prettier-ignore -->
      <CmsTextPicture
        v-if="subcontent.collection === 'text_picture'"
        :text-picture="(subcontent.item as TextPicture)" />

      <!-- prettier-ignore -->
      <CmsSizeWrapper
        v-if="subcontent.collection === 'size_wrapper'"
        :size-wrapper="(subcontent.item as SizeWrapper)" />

      <!-- prettier-ignore -->
      <CmsTextCta
        v-if="subcontent.collection === 'text_cta'"
        :cta-payload="(subcontent.item as TextCTA)" />

      <!-- prettier-ignore -->
      <CmsCardsWrapper
        v-if="subcontent.collection === 'cards'"
        :cards-payload="(subcontent.item as Cards)" />

      <!-- prettier-ignore -->
      <CmsFaq
        v-if="subcontent.collection === 'faq'" 
        :faq="(subcontent.item as Faq)"/>

      <!-- prettier-ignore -->
      <CmsTextListcard
        v-if="subcontent.collection === 'text_listcard'" 
        :payload="(subcontent.item as TextListCard)"/>

      <!-- prettier-ignore -->
      <CmsWasteRule
        v-if="subcontent.collection === 'waste_rule'"
        :waste-rule="(subcontent.item as WasteRule)" />
    </div>
  </ContentWrapper>
</template>

<script setup lang="ts">
import { DirectusModule, contentFieldsFilter } from '~/types/enums'
import type {
  Cards,
  Contact,
  Content,
  CustomTable,
  Faq,
  Infobox,
  ReviewWrapper,
  SizeWrapper,
  Text,
  TextCTA,
  TextListCard,
  TextPicture,
  WasteRule,
} from '~/types/types'

const props = defineProps({
  contentId: {
    type: Number,
    required: true,
  },
})
const { $directus, $readItem } = useNuxtApp()
const backgroundMap = {
  white: 'bg__plain px-0',
  gradient: 'bg__gradient_top px-0',
  three_gradient: 'bg__gradient_top_bottom px-0',
}
const { contentId } = toRefs(props)
const { data } = await useAsyncData(`content-${contentId.value}`, () => {
  return $directus.request(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $readItem(DirectusModule.Content as any, contentId.value, {
      fields: contentFieldsFilter,
    }),
  )
})

const contentData = data.value as unknown as Content
if (!contentData)
  throw createError({
    status: 404,
    statusText: 'Content Not Found',
  })
</script>
